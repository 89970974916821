import axiosService from '@/libs/axiosService'

export const getGenres = () => axiosService({
  url: '/genres',
  method: 'get'
})

export const getTags = () => axiosService({
  url: '/tags',
  method: 'get'
})

export const getEventCategories = () => axiosService({
  url: '/categories/event',
  method: 'get'
})

export const getTimezones = () => axiosService({
  url: '/timezones',
  method: 'get'
})

export const getCountries = () => axiosService({
  url: '/countries',
  method: 'get'
})

export const addTag = (data: any) => axiosService({
  url: '/tags',
  method: 'post',
  data
})

export const addGenre = (data: any) => axiosService({
  url: '/genres',
  method: 'post',
  data
})
