import { $tokenConfig } from '@themeConfig'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem($tokenConfig.storageTokenKeyName)
}

export const getUserData = () => isUserLoggedIn() ? JSON.parse(atob(localStorage.getItem('userData'))) : null

export const getUserAuthToken = () => isUserLoggedIn() ? localStorage.getItem($tokenConfig.storageTokenKeyName) : null

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return { name: 'admin-dashboard' }
  // if (userRole === 'customer') return { name: 'access-control' }
  return '/'
}

export const saveUserLocalStorage = user => {
  localStorage.setItem($tokenConfig.tokenExpiryKey, new Date(Date.now() + $tokenConfig.timeToExpireToken).toString())
  localStorage.setItem($tokenConfig.localStorageKey, 'true')
  localStorage.setItem($tokenConfig.storageUserInfo, btoa(JSON.stringify(user)))
}

export const saveAuthTokenLocalStorage = authToken => {
  localStorage.setItem($tokenConfig.storageTokenKeyName, authToken)
}

export const setSiteConfigLocalStorage = config => {
  localStorage.setItem($tokenConfig.configExpiryKey, new Date(Date.now() + $tokenConfig.timeToExpireToken).toString())
  localStorage.setItem($tokenConfig.siteConfig, btoa(JSON.stringify(config)))
}
export const clearLocalStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
}
