import axiosService from '@/libs/axiosService'
import { ModelPayload } from '@/models/Payloads'

export const getAdminProviderUsers = (payload: ModelPayload) => axiosService({
  url: '/admin/providers',
  method: 'get',
  params: payload.params
})

export const getAdminProviderUser = (payload: ModelPayload) => axiosService({
  url: `/admin/providers/${payload.modelUid}`,
  method: 'get'
})
