import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import {
  loginUserByProvider,
  loginUser,
  registerUser,
  activateAccount,
  updatePassword,
  registerProvider
} from '@/api/auth'
import firebase from 'firebase/app'
import 'firebase/auth'
import { User } from '@/models/User'
import { LoginPayload, ProviderPayload, RegisterPayload, UpdatePasswordPayload } from '@/models/Auth'
import {
  clearLocalStorage,
  getUserAuthToken,
  getUserData,
  saveAuthTokenLocalStorage,
  saveUserLocalStorage
} from '@/auth/utils'
import helper from '@/utils/helper'
import { ProviderItem, RegisterProviderPayload } from '@/models/Provider'

export interface IAuthState {
  activeUser: User,
  tokenExpiryKey: string,
  authToken: string,
  appPayload:any
}

export const AUTH_MODULE_NAME = 'authentication'

@Module({ namespaced: true, name: AUTH_MODULE_NAME })
export default class AuthModule extends VuexModule implements IAuthState {
  public activeUser: User | null = null
  public tokenExpiryKey: string = 'tokenExpiryKey'
  public localStorageKey: string = 'baboolylocalStorageKey'
  public authToken: string |null = null
  public appPayload:any = {
    deviceId: '123456',
    deviceOs: 'web',
    deviceToken: 'some token',
    appVersion: '0.1'
  }

  constructor () {
    super(VuexModule)
    this.activeUser = getUserData()
    this.authToken = getUserAuthToken()
  }

  @Action({ rawError: true })
  public async register (payload: RegisterPayload): Promise<User> {
    payload = {
      ...payload,
      ...this.appPayload
    }

    return new Promise((resolve, reject) => {
      registerUser(payload)
        .then(response => {
          if (response && response.data && response.data.user && response.data.authToken) {
            // console.log('user', response.data.user)
            this.authUser(response.data)
            resolve(response.data.user)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async registerProvider (payload: RegisterProviderPayload): Promise<ProviderItem> {
    payload = {
      ...payload,
      ...this.appPayload
    }
    return new Promise((resolve, reject) => {
      registerProvider(payload)
        .then(response => {
          if (response && response.data && response.data.provider && response.data.authToken) {
            // console.log('provider', response.data.provider)
            this.authUser(response.data)
            resolve(response.data.provider)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async login (payload: LoginPayload): Promise<User> {
    payload = {
      ...payload,
      ...this.appPayload
    }
    return new Promise((resolve, reject) => {
      loginUser(payload)
        .then(response => {
          if (response && response.data && response.data.user && response.data.authToken) {
            // console.log('user', response.data.user)
            this.authUser(response.data)
            resolve(response.data.user)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public loginWithProvider (providerName: string): Promise<User> {
    let provider: any = null
    switch (providerName) {
      case 'twitter':
        provider = new firebase.auth.TwitterAuthProvider()
        break
      case 'google':
        provider = new firebase.auth.GoogleAuthProvider()
        break
      case 'facebook':
        provider = new firebase.auth.FacebookAuthProvider()
        break
      case 'github':
        provider = new firebase.auth.GithubAuthProvider()
        break
      default:
        provider = null
    }
    // console.log(provider)
    return this.signInWithPopupProvider(provider)
  }

  @Action({ rawError: true })
  public async signInWithPopupProvider (provider: any): Promise<User> {
    const providerId = provider.providerId
    const response = await firebase.auth().signInWithPopup(provider)
    if (response && response.user) {
      const user: any = response.user
      const providerUserData: any = user.providerData.find((item: any) => {
        return item.providerId === providerId
      })
      const token: string = user.ya
      if (providerUserData && user) {
        const params: ProviderPayload = {
          email: providerUserData.email,
          provider: providerId,
          providerUserId: providerUserData.uid,
          emailVerified: user.emailVerified,
          token,
          name: providerUserData.displayName ? providerUserData.displayName : '',
          firstName: providerUserData.firstName ? providerUserData.firstName : '',
          lastName: providerUserData.lastName ? providerUserData.lastName : '',
          avatar: providerUserData.photoURL ? providerUserData.photoURL : null,
          ...this.appPayload
        }
        return this.loginUserByProvider(params)
      }
    }
  }

  @Action({ rawError: true })
  public async loginUserByProvider (params: ProviderPayload): Promise<User> {
    return new Promise((resolve, reject) => {
      loginUserByProvider(params)
        .then(response => {
          if (response && response.data && response.data.user && response.data.authToken) {
            // console.log('user', response.data.user)
            this.authUser(response.data)
            resolve(response.data.user)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // TODO complete implementatiopn after API is ready
  @Action({ rawError: true })
  public async passwordChange (payload: UpdatePasswordPayload) {
    return new Promise((resolve, reject) => {
      updatePassword(payload)
        .then(response => {
          // If there's user data in response
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // TODO complete implementatiopn after API is ready
  @Action({ rawError: true })
  public async activateAccount (token: string) {
    return new Promise((resolve, reject) => {
      activateAccount(token)
        .then(response => {
          // If there's user data in response
          if (response.data) {
            resolve(response)
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ message: 'Something went wrong! gg' })
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // @ts-ignore
  @MutationAction({
    rawError: true,
    mutate: ['activeUser', 'authToken']
  })
  authUser (data: any) {
    const user: User = data.user
    const authToken = data.authToken
    user.userRole = helper.getUserRole(user)
    saveUserLocalStorage(user)
    saveAuthTokenLocalStorage(authToken)
    return { activeUser: user, authToken: authToken }
  }

  // @ts-ignore
  @MutationAction({
    rawError: true,
    mutate: ['activeUser', 'authToken']
  })
  logout () {
    clearLocalStorage()
    return { activeUser: null, authToken: '' }
  }

  get isAdmin (): boolean {
    return this.activeUser.userRole.includes('admin')
  }
}
