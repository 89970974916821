export default [
  {
    path: '/admin',
    component: () => import('@/views/admin/AdminView.vue'),
    children: [
      {
        path: '/',
        name: 'admin-dashboard',
        component: () => import('@/views/admin/dashboard/AdminDashboard.vue'),
        meta: {
          pageTitle: 'Admin Panel'
        }
      },
      // NEW USERS LIST
      {
        path: '/admin/new-users',
        name: 'new-admin-users-list',
        props: true,
        component: () => import('@/views/admin/NEW-users/AdminUsersList'),
        meta: {
          pageTitle: 'Users'
        }
      },
      {
        path: '/admin/users/:uid',
        name: 'admin-user-details',
        props: true,
        component: () => import('@/views/admin/NEW-users/AdminUserDetails'),
        meta: {
          pageTitle: 'Account Details'
        }

      },

      // Customer
      {
        path: '/admin/customers',
        name: 'admin-customers-list',
        props: true,
        component: () => import('@/views/admin/customers/AdminCustomersList.vue'),
        meta: {
          pageTitle: 'customers'
        }
      },
      {
        path: '/admin/customers/:uid',
        name: 'admin-customers-details',
        props: true,
        component: () => import('@/views/admin/customers/AdminCustomerDetails.vue'),
        meta: {
          pageTitle: 'Customer'
        }
      },
      {
        path: '/admin/customers/:uid/vehicles',
        name: 'admin-customers-vehicles',
        props: true,
        component: () => import('@/views/admin/customers/AdminCustomerDetails.vue'),
        meta: {
          pageTitle: 'Customer Cars'
        }
      },
      {
        path: '/admin/customers/:uid/orders',
        name: 'admin-customers-orders',
        props: true,
        component: () => import('@/views/admin/customers/AdminCustomerDetails.vue'),
        meta: {
          pageTitle: 'Customer Orders'
        }
      },
      // Providers
      {
        path: '/admin/providers',
        name: 'admin-providers-list',
        props: true,
        component: () => import('@/views/admin/providers/AdminProvidersList.vue'),
        meta: {
          pageTitle: 'Providers'
        }
      },
      {
        path: '/admin/providers/:uid',
        name: 'admin-provider-details',
        props: true,
        component: () => import('@/views/admin/providers/AdminProviderDetails.vue'),
        meta: {
          pageTitle: 'Provider details'
        }
      },
      {
        path: '/admin/providers/:uid/orders',
        name: 'admin-provider-orders',
        props: true,
        component: () => import('@/views/admin/providers/AdminProviderOrders.vue'),
        meta: {
          pageTitle: 'Provider Orders'
        }
      },
      // User
      {
        path: '/admin/users',
        name: 'admin-users-list',
        props: true,
        component: () => import('@/views/admin/users/AdminUsersList.vue'),
        meta: {
          pageTitle: 'Admin Users'
        }
      },
      {
        path: '/admin/users/create',
        name: 'admin-add-user',
        component: () => import('@/views/admin/users/AdminAddUser.vue'),
        meta: {
          pageTitle: 'New Admin Users'
        }
      },
      // Vehicles
      {
        path: '/admin/vehicles',
        name: 'admin-vehicles-list',
        component: () => import('@/views/admin/vehicles/AdminVehiclesList.vue'),
        meta: {
          pageTitle: 'Vehicles List'
        }
      },

      // Services
      {
        path: '/admin/services',
        name: 'admin-services-list',
        component: () => import('@/views/admin/services/AdminServicesList.vue'),
        meta: {
          pageTitle: 'Services'
        }
      },
      // Orders
      {
        path: '/admin/orders',
        name: 'admin-orders-list',
        component: () => import('@/views/admin/orders/AdminOrdersList.vue'),
        meta: {
          pageTitle: 'Orders'
        }
      }
    ]
  }

]
