import { Action, Module, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { Pagination } from '@/models/General'
import { ModelPayload } from '@/models/Payloads'
import { ServiceItem } from '@/models/Service'
import { createAdminService, getAdminService, getAdminServices, updateAdminService } from '@/api/services'

export const SERVICE_MODULE_NAME = 'service'

@Module({
  namespaced: true,
  store,
  name: SERVICE_MODULE_NAME
})
export class ServiceModule extends VuexModule {
  constructor () {
    super(VuexModule)
  }

  @Action({ rawError: true })
  public async fetchAdminServices (payload: ModelPayload): Promise<Pagination> {
    return new Promise((resolve, reject) => {
      getAdminServices(payload)
        .then(response => {
          if (response && response.data && response.data.services) {
            const services: Pagination = response.data.services
            resolve(services)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async fetchAdminService (payload: ModelPayload): Promise<ServiceItem> {
    return new Promise((resolve, reject) => {
      getAdminService(payload)
        .then(response => {
          if (response && response.data && response.data.service) {
            const service: ServiceItem = response.data.service
            resolve(service)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async createAdminService (payload: ModelPayload): Promise<ServiceItem> {
    return new Promise((resolve, reject) => {
      createAdminService(payload)
        .then(response => {
          if (response && response.data && response.data.service) {
            const service: ServiceItem = response.data.service
            resolve(service)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async updateAdminService (payload: ModelPayload): Promise<ServiceItem> {
    return new Promise((resolve, reject) => {
      updateAdminService(payload)
        .then(response => {
          if (response && response.data && response.data.service) {
            const service: ServiceItem = response.data.service
            resolve(service)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }
}
