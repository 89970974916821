import axiosService from '@/libs/axiosService'
import { ModelPayload } from '@/models/Payloads'

export const getAdminOrdersList = (payload: ModelPayload) => axiosService({
  url: '/admin/orders',
  method: 'get',
  params: payload.params
})

export const getAdminOrderDetails = (payload: ModelPayload) => axiosService({
  url: `/admin/orders/${payload.modelUid}`,
  method: 'get'
})
