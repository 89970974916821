import axiosService from '@/libs/axiosService'
import { ModelPayload } from '@/models/Payloads'

export const getAdminCustomerUsers = (payload: ModelPayload) => axiosService({
  url: '/admin/customers',
  method: 'get',
  params: payload.params
})

export const getAdminCustomerUser = (payload: ModelPayload) => axiosService({
  url: `/admin/customers/${payload.modelUid}`,
  method: 'get'
})
