export const userAbility = {
  admin: [{
    subject: 'all', actions: 'manage'
  }, {
    action: 'read',
    subject: 'Public'
  },
  {
    action: 'read',
    subject: 'Customer'
  }],
  customer: [
    {
      action: 'read',
      subject: 'Public'
    },
    {
      action: 'read',
      subject: 'Customer'
    }],
  public: [
    {
      action: 'read',
      subject: 'Public'
    }]
}

export const _ = undefined
