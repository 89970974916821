export default [
  {
    path: '/',
    name: 'home-page',
    component: () => import('@/views/pages/HomePage.vue'),
    meta: {
      resource: 'Public',
      action: 'read',
      layout: 'public',
      pageTitle: 'Washir Home Page',
      hiddenBreadcrumb: true,
      contentClass: 'no-padding-content',
      footerTypeClass: 'no-padding-footer'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/pages/public/Terms.vue'),
    meta: {
      pageTitle: 'Terms and condition',
      hiddenBreadcrumb: true,
      contentClass: 'no-padding-content',
      footerTypeClass: 'no-padding-footer',
      resource: 'Public',
      layout: 'public'

    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/pages/public/Privacy.vue'),
    meta: {
      pageTitle: 'Privacy',
      hiddenBreadcrumb: true,
      contentClass: 'no-padding-content',
      footerTypeClass: 'no-padding-footer',
      resource: 'Public',
      layout: 'public'

    }
  },
  {
    path: '/about',
    name: 'about-us',
    component: () => import('@/views/pages/public/About.vue'),
    meta: {
      pageTitle: 'About Washir',
      hiddenBreadcrumb: true,
      contentClass: 'no-padding-content',
      footerTypeClass: 'no-padding-footer',
      resource: 'Public',
      action: 'read',
      layout: 'public'

    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/pages/public/Faq.vue'),
    meta: {
      pageTitle: 'faq',
      hiddenBreadcrumb: true,
      contentClass: 'no-padding-content',
      footerTypeClass: 'no-padding-footer',
      resource: 'Public',
      layout: 'public'

    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/pages/public/Contact.vue'),
    meta: {
      pageTitle: 'contact',
      hiddenBreadcrumb: true,
      contentClass: 'no-padding-content',
      footerTypeClass: 'no-padding-footer',
      resource: 'Public',
      layout: 'public'

    }
  },
  {
    path: '/washir-pro',
    name: 'washir-pro',
    component: () => import('@/views/pages/public/WashirPro.vue'),
    meta: {
      pageTitle: 'Washir Pro',
      hiddenBreadcrumb: true,
      contentClass: 'no-padding-content',
      footerTypeClass: 'no-padding-footer',
      resource: 'Public',
      layout: 'public'

    }
  }

]
