import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import { Timezone } from '@/models/Timezone'
import { getTimezones } from '@/api/general'

export interface ITimezoneState {
  timezones: Timezone[]
  hoursList: string[]

}

export const TIMEZONE_MODULE_NAME = 'timezone'

@Module({
  namespaced: true,
  store,
  name: TIMEZONE_MODULE_NAME
})
export class TimezoneModule extends VuexModule implements ITimezoneState {
  public timezones: Timezone[] = []
  public hoursList: string[] = []

  // @ts-ignore
  @MutationAction({
    rawError: true,
    mutate: ['timezones']
  })
  public async fetchTimezones () {
    try {
      const response = await getTimezones()
      if (response && response.data && response.data) {
        return { 'timezones': response.data }
      }
      return []
    } catch (e) {
      throw new Error('Timezones not found')
    }
  }

  // @ts-ignore
  @MutationAction({
    rawError: true,
    mutate: ['hoursList']
  })
  public async fetchHoursList () {
    const quarterHours = ['00', '15', '30', '45']
    const amPm = ['AM', 'PM']
    const times = []
    for (let l = 0; l < 2; l++) {
      for (let i = 0; i < 12; i++) {
        for (let j = 0; j < 4; j++) {
          let n = i === 0 ? 12 : i
          times.push(('0' + n).slice(-2) + ':' + quarterHours[j] + ' ' + amPm[l])
        }
      }
    }
    return { 'hoursList': times }
  }

  get getTimezones () {
    return this.timezones
  }

  get getHoursList () {
    return this.hoursList
  }
}
