import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import { SiteConfig, emptyConfig, ConfigPayload, CountryItem } from '@/models/SiteConfig'
import { getConfig } from '@/api/config'
import http from 'axios'
import { saveUserLocalStorage } from '@/auth/utils'
import { getCountries } from '@/api/general'

export const SIT_CONFIG_MODULE_NAME = 'siteConfig'
export interface ISiteConfigState {
  siteConfig: SiteConfig | null
  clientIp: string
  configExpiryKey: string
  countries: CountryItem[]
}

@Module({
  namespaced: true,
  store,
  name: SIT_CONFIG_MODULE_NAME
})
export class SiteConfigModule extends VuexModule implements ISiteConfigState {
  public siteConfig: SiteConfig | null = emptyConfig()
  public clientIp: string = ''
  public configExpiryKey: string = ''
  public countries: CountryItem[] = []

  constructor () {
    super(VuexModule)
    if (!this.siteConfig || !this.clientIp || this.siteConfig.clientIp === '') {
      if (localStorage.length > 1 && new Date().valueOf() < Date.parse(localStorage.getItem('configExpiryKey'))) {
        // console.log('Loading Data from localStorage!')
        const siteConfig = localStorage.getItem('siteConfig')

        if (siteConfig) {
          this.siteConfig = JSON.parse(atob(siteConfig))
          this.clientIp = this.siteConfig.clientIp
        }
        // console.log('this.siteConfig', this.siteConfig)
      }
    } else if (localStorage.length > 1 && new Date().valueOf() < Date.parse(localStorage.getItem('configExpiryKey'))) {
      this.clientIp = ''
      this.siteConfig.clientIp = ''
    }
  }

  @Mutation
  private SET_CONFIG (config: SiteConfig) {
    this.siteConfig = config
    saveUserLocalStorage(config)
  }

  @Mutation
  private SET_CLIENT_IP (ip: string) {
    this.siteConfig.clientIp = ip
    this.clientIp = ip
    saveUserLocalStorage(this.siteConfig)
  }

  @Action({ rawError: true })
  public async fetchConfig (payload: ConfigPayload): Promise<SiteConfig> {
    return new Promise((resolve, reject) => {
      getConfig(payload)
        .then(response => {
          if (response && response.data && response.data.userLocation) {
            const config: SiteConfig = {
              clientIp: response.data.userLocation.ip,
              userLocation: response.data.userLocation
            }
            this.SET_CONFIG(config)
            resolve(config)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async fetchClientIpAddress () {
    const currentIp = this.clientIp
    let newIp = 'xxx.xxx.xxx.xxx'
    const number: number = 2

    switch (number) {
      case 1:
        // try {
        //   const response = await http.get('https://www.cloudflare.com/cdn-cgi/trace')
        //   console.log(response.data)
        //   return response
        // } catch (e) {
        //   console.log('cloudflare didn\'t work', e.response)
        //   return null
        // }
        break

      case 2:
        try {
          const response = await http.get('https://api.ipify.org/?format=json')
          if (response && response.data && response.data.ip) {
            newIp = response.data.ip
          }
        } catch (e) {
          // console.log('api6 didn\'t work', e.response)
        }
        break
      default:
        try {
          const response = await http.get('https://ifconfig.me/all.json')
          if (response && response.data && response.data.ip_addr) {
            newIp = response.data.ip_addr
          }
        } catch (e) {
          // console.log('ifconfig didn\'t work', e.response)
        }
    }

    if (newIp !== 'xxx.xxx.xxx.xxx' && newIp !== currentIp) {
      await this.fetchConfig({
        ip: newIp
      })
      this.SET_CLIENT_IP(newIp)
    }
  }

  // @ts-ignore
  @MutationAction({
    rawError: true,
    mutate: ['countries']
  })

  public async fetchCountries () {
    try {
      const response = await getCountries()
      if (response && response.data && response.data) {
        return { 'countries': response.data }
      }
      return []
    } catch (e) {
      throw new Error('Countries not found')
    }
  }

  get getSiteConfig () {
    return this.siteConfig
  }

  get getClientIp () {
    return this.clientIp
  }
}
