import axiosService from '@/libs/axiosService'
import { ModelPayload } from '@/models/Payloads'
import { ReviewPayload } from '@/models/Review'

export const getAdminServices = (payload: ModelPayload) => axiosService({
  url: '/admin/services',
  method: 'get',
  params: payload.params
})

export const getAdminService = (payload: ModelPayload) => axiosService({
  url: `/admin/service/${payload.modelUid}`,
  method: 'get'
})

export const createAdminService = (payload: ModelPayload) => axiosService({
  url: '/admin/services',
  method: 'post',
  data: payload.data
})
export const deleteAdminService = (payload: ModelPayload) => axiosService({
  url: `/admin/service/${payload.modelUid}`,
  method: 'delete'
})

export const updateAdminService = (payload: ModelPayload) => axiosService({
  url: `/admin/service/${payload.modelUid}`,
  method: 'patch',
  data: payload.data
})
