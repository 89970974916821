export default [
  {
    path: '/account',
    name: 'account-setting',
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      layout: 'public',
      hiddenBreadcrumb: true,
      contentClass: 'no-padding-content',
      footerTypeClass: 'no-padding-footer',
      resource: 'Customer',
      action: 'read'
    }
  }
]
