import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import AuthModule, { AUTH_MODULE_NAME } from './modules/authModule'
import { SIT_CONFIG_MODULE_NAME, SiteConfigModule } from '@/store/modules/siteConfigModule'
import { TIMEZONE_MODULE_NAME, TimezoneModule } from '@/store/modules/timezoneModule'
import { CUSTOMER_MODULE_NAME, CustomerModule } from '@/store/modules/customerModule'
import { PROVIDER_MODULE_NAME, ProviderModule } from '@/store/modules/providerModule'
import { SERVICE_MODULE_NAME, ServiceModule } from '@/store/modules/serviceModule'
import { ORDER_MODULE_NAME, OrderModule } from '@/store/modules/orderModule'
import { ADMIN_MODULE_NAME, UserModule } from '@/store/modules/userModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    [AUTH_MODULE_NAME]: AuthModule,
    [SIT_CONFIG_MODULE_NAME]: SiteConfigModule,
    [TIMEZONE_MODULE_NAME]: TimezoneModule,
    [CUSTOMER_MODULE_NAME]: CustomerModule,
    [PROVIDER_MODULE_NAME]: ProviderModule,
    [SERVICE_MODULE_NAME]: ServiceModule,
    [ORDER_MODULE_NAME]: OrderModule,
    [ADMIN_MODULE_NAME]: UserModule,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule
  },
  strict: process.env.DEV
})
