import { Action, Module, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { Pagination } from '@/models/General'
import { ModelPayload } from '@/models/Payloads'
import { OrderRespondItem } from '@/models/Order'
import { getAdminOrdersList, getAdminOrderDetails } from '@/api/orders'

export const ORDER_MODULE_NAME = 'order'

@Module({
  namespaced: true,
  store,
  name: ORDER_MODULE_NAME
})
export class OrderModule extends VuexModule {
  constructor () {
    super(VuexModule)
  }

  @Action({ rawError: true })
  public async fetchAdminOrders (payload: ModelPayload): Promise<Pagination> {
    return new Promise((resolve, reject) => {
      getAdminOrdersList(payload)
        .then(response => {
          if (response && response.data && response.data.orders) {
            const ordersPagination: Pagination = response.data.orders
            resolve(ordersPagination)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async fetchAdminOrderDetails (payload: ModelPayload): Promise<OrderRespondItem> {
    return new Promise((resolve, reject) => {
      getAdminOrderDetails(payload)
        .then(response => {
          if (response && response.data && response.data.order) {
            const orderItem: OrderRespondItem = response.data.order
            resolve(orderItem)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }
}
