import { Action, Module, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { Pagination } from '@/models/General'
import { getAdminProviderUser, getAdminProviderUsers } from '@/api/providers'
import { ModelPayload, ParamsPayload } from '@/models/Payloads'
import { ProviderItem } from '@/models/Provider'

export const PROVIDER_MODULE_NAME = 'provider'

@Module({
  namespaced: true,
  store,
  name: PROVIDER_MODULE_NAME
})
export class ProviderModule extends VuexModule {
  constructor () {
    super(VuexModule)
  }

  @Action({ rawError: true })
  public async fetchAdminProviderUsers (payload: ModelPayload): Promise<Pagination> {
    return new Promise((resolve, reject) => {
      getAdminProviderUsers(payload)
        .then(response => {
          if (response && response.data && response.data.providers) {
            const providers: Pagination = response.data.providers
            resolve(providers)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async fetchAdminProviderUser (payload: ModelPayload): Promise<ProviderItem> {
    return new Promise((resolve, reject) => {
      getAdminProviderUser(payload)
        .then(response => {
          if (response && response.data && response.data.provider) {
            const provider: ProviderItem = response.data.provider
            resolve(provider)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }
}
