import axiosService from '@/libs/axiosService'
import { LoginPayload, ProviderPayload, RegisterPayload, UpdatePasswordPayload } from '@/models/Auth'
import { RegisterProviderPayload } from '@/models/Provider'

export const me = () => axiosService({
  url: '/me',
  method: 'get'
})

export const loginUser = (data: LoginPayload) => axiosService({
  url: 'auth',
  method: 'post',
  data
})

export const loginUserByProvider = (data: ProviderPayload) => axiosService({
  url: '/auth/provider',
  method: 'post',
  data
})

export const registerUser = (data: RegisterPayload) => axiosService({
  url: '/register',
  method: 'post',
  data
})

export const registerProvider = (data: RegisterProviderPayload) => axiosService({
  url: '/provider',
  method: 'post',
  data
})

export const updatePassword = (data: UpdatePasswordPayload) => axiosService({
  url: '/auth/password',
  method: 'post',
  data
})

export const activateAccount = (token: string) => axiosService({
  url: `/auth/verify/${token}`,
  method: 'post'
})

//  TODO MUST BE CHECKED
// export const resetPassword = (data: any) => axiosService({
//   url: '/auth/reset',
//   method: 'post',
//   data
// })
//
// export const updateEmail = (data: any) => axiosService({
//   url: '/auth/email',
//   method: 'post',
//   data
// })
//
// export const sendResetPasswordLink = (data: any) => axiosService({
//   url: '/auth/reset-password',
//   method: 'post',
//   data
// })
