// @ts-ignore
import moment from 'moment'
import { User } from '@/models/User'
import { LocationItem } from '@/models/Location'

export default {
  cleanCopy (obj: object) {
    return JSON.parse(JSON.stringify(obj))
  },
  formattedDateForRequest (date: Date) {
    return moment(date).format('YYYY-MM-DD HH:mm')
  },
  isSmallerScreen (): boolean {
    return window.innerWidth < 768
  },
  getBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  },
  limitAndSortedComments (comments: any, limit: number, sortedMode: string): any {
    let result = Array.from(comments)
    if (limit && comments.length > limit) {
      result = result.slice(0, limit)
    }

    if (sortedMode === 'desc') {
      result = result.reverse()
    }
    return result
  },

  getUserRole (user: User) {
    let role: string = 'public'
    // TODO make user with the role
    role = 'admin'

    if (user && user.roles) {
      const admin = Object.values(user.roles).filter(item => item === 'Admin')
      const editor = Object.values(user.roles).filter(item => item === 'Editor')
      const customer = Object.values(user.roles).filter(item => item === 'Customer')
      return admin.length === 1 ? 'admin' : editor.length === 1 ? 'editor' : customer.length === 1 ? 'customer' : role
    }
  },

  getGoogleMapLink (location: LocationItem): string {
    const address = location.address1 +
      ', ' +
      location.address2 +
      ', ' +
      location.city +
      ', ' +
      location.zipcode

    return 'https://www.google.com/maps/search/?api=1&query=' + address
  }

}
