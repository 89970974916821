import Vue from 'vue'

// axios
import axios from 'axios'
import _ from 'lodash'
import AuthModule from '@/store/modules/authModule'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'

const axiosService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

const camelCaseObjectKey = obj => _.mapKeys(obj, (value, key) => _.camelCase(key))

const camelCaseObject = obj => {
  const isArray = Array.isArray(obj)
  if (typeof obj === 'string' || _.isEmpty(obj)) {
    return obj
  }
  obj = camelCaseObjectKey(obj)
  const values = _.mapValues(obj, value => camelCaseObject(value))
  return isArray ? Object.values(values) : values
}

const convertToSnakeCase = obj => _.transform(obj, (acc:any, value:any, key:any, target:any) => {
  const snakeKey = _.isArray(target) ? key : _.snakeCase(key)
  acc[snakeKey] = _.isObject(value) ? convertToSnakeCase(value) : value
})
// Request interceptors
axiosService.interceptors.request.use(
  config => {
    // config.data = _.mapKeys(config.data, (value, key) => _.snakeCase(key))
    config.data = convertToSnakeCase(config.data)
    config.params = convertToSnakeCase(config.params)
    // Add X-Access-Token header to every request, you can add other custom headers here
    config.headers['X-Api-Key'] = process.env.VUE_APP_API_KEY
    const { authToken } = getModule(AuthModule, store)
    if (authToken && authToken !== '') {
      config.headers['X-Access-Token'] = authToken
      config.headers.common.Authorization = `Bearer ${authToken}`
    }
    return config
  }, error => {
    Promise.reject(error)
  }
)

// Response interceptors
axiosService.interceptors.response.use(
  response => {
    return response && response.data ? camelCaseObject(response) : response
  },
  err => {
    const errorData = err.response.data
    // console.log(errorData)
    return Promise.reject(errorData)
  }
)

export default axiosService
