import axiosService from '@/libs/axiosService'
import {
  LoginPayload, ProviderPayload, RegisterPayload, UpdatePasswordPayload
} from '@/models/Auth'
import { ModelPayload } from '@/models/Payloads'

// TODO must be created
export const inviteUser = (payload: ModelPayload) => axiosService({
  url: '/invite-user',
  method: 'post',
  data: payload.data
})

// ADMIN
export const getAdminUsers = (payload: ModelPayload) => axiosService({
  url: '/admin/users',
  method: 'get',
  params: payload.params
})

export const getAdminUser = (payload: ModelPayload) => axiosService({
  url: `/admin/users/${payload.modelUid}`,
  method: 'get'
})

export const getUserInfomration = (payload: LoginPayload) => axiosService({
  url: '/me',
  method: 'get'
})
