// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}
// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'Washir',
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/logo.svg')
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: '' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true
  }

}
export const $firebaseConfig = {
  apiKey: 'AIzaSyCMYPcLeW0_Ury2czdHCvD129tSAjHnTIE',
  authDomain: 'washir-a1016.firebaseapp.com',
  databaseURL: 'https://washir-a1016.firebaseio.com',
  projectId: 'washir-a1016',
  storageBucket: 'washir-a1016.appspot.com',
  messagingSenderId: '819683378314',
  appId: '1:819683378314:web:5ae9877965f42e243b1973'
}

export const $tokenConfig = {
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'authToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserInfo: 'userData',
  storageTokenExpiryKey: 'tokenExpiryKey',
  storageConfigExpiryKey: 'configExpiryKey',
  storageSiteConfig: 'siteConfig',
  localStorageKey: 'defaultStorageKey',
  timeToExpireToken: (3600 * 1000 * 24 * 365) // time To expire token  in milliseconds 1 year

}
