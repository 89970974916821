import { Ability } from '@casl/ability'
import { userAbility } from './config'
import { getUserData } from '@/auth/utils'
import helper from '@/utils/helper'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = getUserData()
const userRole = helper.getUserRole(userData)
const existingAbility = userData && userRole ? userAbility[userRole] : userAbility.public

export default new Ability(existingAbility || userAbility.public)
