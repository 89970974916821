import store from '@/store'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ModelPayload } from '@/models/Payloads'
import { UserItem } from '@/models/User'
import { Pagination } from '@/models/General'
import { getAdminUser, getAdminUsers, inviteUser, getUserInfomration } from '@/api/users'
import { SuccessMessage } from '@/models/App'
import { LoginPayload } from '@/models/Auth'

export interface IUserState {
}

export const ADMIN_MODULE_NAME = 'user'

@Module({ namespaced: true, store, name: ADMIN_MODULE_NAME })
export class UserModule extends VuexModule implements IUserState {
  constructor () {
    super(VuexModule)
  }

  @Action({ rawError: true })
  public async fetchAdminUsers (payload: ModelPayload): Promise<Pagination> {
    return new Promise((resolve, reject) => {
      getAdminUsers(payload)
        .then(response => {
          if (response && response.data && response.data.users) {
            const users: Pagination = response.data.users
            resolve(users)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }
  @Action({ rawError: true })
  public async fetchAdminUser (payload: ModelPayload): Promise<UserItem> {
    return new Promise((resolve, reject) => {
      getAdminUser(payload)
        .then(response => {
          if (response && response.data && response.data.user) {
            const user: UserItem = response.data.user
            resolve(user)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  // TODO
  @Action({ rawError: true })
  public async inviteUser (payload: ModelPayload): Promise<SuccessMessage> {
    return new Promise((resolve, reject) => {
      inviteUser(payload)
        .then(response => {
          if (response && response.data && response.data.message) {
            resolve(response.data.message)
          }
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  public async getUserInformation (payload: LoginPayload) : Promise<UserItem> {
    return new Promise((resolve, reject) => {
      getUserInfomration(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          // console.log('Error message : ', error.response)
          reject(error)
        })
    })
  }
}
