export interface SiteConfig {
  clientIp: string
  userLocation: UserLocation

}

export interface UserLocation {
  timezone: string,
  lat: string,
  lng: string,
  region: string,
  regionName: string,
  zipcode: string,
  city: string,
  country: string,
  countryCode: string,
  currencyCode: string,
  currencySymbol: string,
  currencySymbolUtf8: string
}

export function emptyConfig (): SiteConfig {
  return {
    clientIp: '',
    userLocation: {
      timezone: '',
      lat: '',
      lng: '',
      region: '',
      regionName: '',
      zipcode: '',
      city: '',
      country: '',
      countryCode: '',
      currencyCode: '',
      currencySymbol: '',
      currencySymbolUtf8: ''
    }
  }
}

export type ConfigPayload = {
  ip: string
}

export type CountryItem = {
  name: string,
  code: string
}
